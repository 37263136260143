const Agreement = () => import('@/views/Agreement')

export default [
  {
    path: '/agreement',
    component: Agreement,
    meta: { requireAuth: true }
  }

]
