import router from './router'
import store from '@/store'
import { isIOS } from '@/utils/detect'
const localStorageUserInfo = localStorage.getItem('userInfo')

router.beforeEach((to, from, next) => {
  // 如果是安卓设备则直接跳转到空白页
  if (!isIOS) {
    return
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 设备号
  const { ixabisoelinye, a, b, c, d, e, f, g, h, j = '', k = '', apitype } = to.query
  if (a || b || c || d || e || f || g || h) {
    const obj = {
      a,
      b,
      c,
      d,
      e,
      f,
      g,
      h,
      j,
      k
    }
    sessionStorage.setItem('abcdefgh', JSON.stringify(obj))
  }
  if (apitype) {
    sessionStorage.setItem('apitype', apitype)
  }
  if (ixabisoelinye) {
    sessionStorage.setItem('ixabisoelinye', ixabisoelinye)
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    const { userInfo = {} } = store.state
    const { usdrerID } = userInfo
    if (usdrerID) {
      return next()
    }
    if (localStorageUserInfo) {
      const u = JSON.parse(localStorageUserInfo)
      store.commit('recordInfo', {
        type: 'userInfo',
        value: u
      })
      return next()
    }
    next({ path: '/login' })
  } else {
    next()
  }
})

// 解决更新代码后 Error:Loading chunk {n} failed.的问题
router.onError(err => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = err.message.match(pattern)
  if (isChunkLoadFailed) {
    const chunkBool = sessionStorage.getItem('chunkError')
    const nowTimes = Date.now()
    if (chunkBool === null || (chunkBool && nowTimes - parseInt(chunkBool) > 60000)) {
      // 路由跳转报错,href手动跳转
      sessionStorage.setItem('chunkError', 'reload')
      const targetPath = router.history.pending.fullPath
      location.href = location.protocol + '//' + location.host + targetPath
    } else if (chunkBool === 'reload') {
      // 手动跳转后依然报错,强制刷新
      sessionStorage.setItem('chunkError', Date.now())
      location.reload(true)
    }
  }
})
export default router
