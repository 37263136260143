<template>
  <div class="header" :style="{ color, backgroundColor: bgColor }">
    <div v-if="showBackButton" @click="goBack" class="back-btn">
      <img src="@/assets/img/header-back-btn.png" />
    </div>
    <div v-if="showLogOut" @click="logOut" class="back-btn">Keluar</div>
    {{ title }}
    <div
      v-if="showCustomerService"
      class="Service"
      @click="showCustomerServiceModal"
    >
      Customer Service
    </div>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
      class="verify-img-dialog"
    >
      <div class="customer-service-body">
        <div class="customer-service-title">Customer Service</div>
        <img src="@/assets/img/kefu.png" alt="" />
        <div class="Email">
          <div>Email</div>
          <span class="text">danaonlinecs@outlook.com</span>
        </div>
        <button type="button" class="confirm-btn" @click="onCloseDialog()">
          Mengerti
        </button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import store from '@/store'
import router from '@/router'
export default {
  name: 'BaseHeaderBar',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    title: {
      type: String,
      default: 'Dana Online'
    },
    showCustomerService: {
      type: Boolean,
      default: true
    },
    showCustomerDaftar: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    showLogOut: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    bgColor: {
      type: String
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    showCustomerServiceModal () {
      this.visible = true
    },

    // 退出登录
    logOut () {
      store.commit('recordInfo', {
        type: 'userInfo',
        value: {}
      })
      localStorage.removeItem('userInfo')
      router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 88px;
  line-height: 88px;
  font-size: 34px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  z-index: 3;
}

.back-btn {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding-left: 30px;
  height: 34px;
  width: 21px;
  cursor: pointer;

  img {
    width: 21px;
    height: 34px;
  }
}

.right-block,
.Service {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
}
.Service {
  color: #fff;
}

.customer-service-title {
  padding-top: 30px;
  font-size: 40px;
  font-weight: 800;
  color: #ffffff;
}
.verify-img-dialog {
  width: 563px;
}

.customer-service-body {
  width: 563px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  img {
    width: 231px;
    height: 227px;
    margin-top: 72px;
  }
  .Email {
    margin-top: 34px;

    div {
      font-size: 30px;
      font-weight: bold;
      color: #fff;
      line-height: 30px;
    }
    a {
      font-size: 34px;
      font-weight: 500;
      color: #fff;
      line-height: 78px;
    }
  }

  .confirm-btn {
    .submit-btn;
    width: 500px;
    margin-top: 28px;
    margin-bottom: 60px;
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
