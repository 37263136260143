<template>
  <div id="app">
    <keep-alive :include="['Identity', 'BasicInfo', 'AddressBook']">
      <router-view />
    </keep-alive>
    <Loading :isLoading="showLoading"></Loading>
  </div>
</template>

<script>
import Loading from '@/components/loading'
import { mapState } from 'vuex'

export default {
  computed: mapState(['showLoading']),
  components: {
    Loading
  }
}
</script>

<style lang="less">
#app {
  font-family: PingFangSC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: url("./assets/img/app_bg.png") no-repeat;
  background-size: cover;
}
</style>
